    const generateHeaderFooterField = (form_header_footer_fields, custom_usage, use_in_form_data = false) => {
        let result = form_header_footer_fields.reduce(function (r, a) {
            r[custom_usage] = r[custom_usage] || [];
            if(a.usage == custom_usage) {
                if(use_in_form_data) {
                    if(a.field && a.field.source_type == 'api') {
                        generateDropdownValueFromAPIField(a.field.source, a.field.api_method).then(response => { 
                            a.field.field_drop_down_values_data = response; 
                        })
                    }
                }
                r[custom_usage].push(a);
            }
            return r;
        }, Object.create(null));
        let final = Object.entries(result).reduce((newObj, [key,val]) => {
            newObj[key] = val.reduce((arr, a) => {
                arr[a.row] = arr[a.row] || [];
                arr[a.row].push(a);
                return arr;
            }, {})
            return newObj;
        },Object.create(null))

        
        for(let fin in final[custom_usage]) {
            final[custom_usage][fin] = final[custom_usage][fin].sort(function(a, b) {
                return a.column - b.column;
            });
        }
        return final;
    }
    const generateDropdownValueFromAPIField =(api, dynamic_method) =>  {
        var customInstance = axios.create();
        customInstance.defaults.headers.common = {};
        return new Promise((resolve, reject) => {
            customInstance( 
                            {method: dynamic_method.toLowerCase(), url: api},
                            {validateStatus: () => true}
                        )
            .then(res => {
                switch(res.status) {
                    case 200: 
                        resolve(res.data.data);
                        break;

                }
            }).catch((error) => {
                // console.log(error)
                if(error.message == "Network Error") {
                    Vue.swal({
                        icon: "error",
                        title: "Network Error!",
                        text: "Please call system administrator",
                    })
                }
                else if(error.message == "Request failed with status code 401") {
                    Vue.swal({
                        icon: "error",
                        title: "Unauthorized!",
                        html: error.response.data ? error.response.data.message : 'Invalid Token.',
                    })
                }
                else if(error.message == "Request failed with status code 405") {
                    Vue.swal({
                        icon: "error",
                        title: "API Access Forbidden!",
                        html: error.response.data ? error.response.data.message : 'Method not allowed.',
                    })
                }
                else if(error.message == "Request failed with status code 403") {
                    Vue.swal({
                        icon: "error",
                        title: "API Access Forbidden!",
                        html: 'Method not allowed.',
                    })
                }
                else {
                    Vue.swal({
                        icon: "error",
                        title: error.message,
                        text: 'Something went wrong, please call system administrator.',
                    })
                }
            })
        })
    }
    const generateHeaderFooterFieldValue =(array) =>  {
        let result = {};
        for (let index = 0; index < array.length; index++) {
            const element = array[index];
            if(element.field && element.form_header_footer_field_value) {
                let el = {
                    [element.id]: element.form_header_footer_field_value.value 
                }
                Object.assign(result, el)
            }
        }
        return result;
    }
    const generateDetailField =(form_detail_fields, use_in_form_data = false) =>  {
        let row = [];
        for (let index = 0; index < form_detail_fields.length; index++) {
            const element = form_detail_fields[index];
            if(use_in_form_data) {
                if(element.field && element.field.source_type == 'api') {
                    generateDropdownValueFromAPIField(element.field.source, element.field.api_method).then(response => { 
                        element.field.field_drop_down_values_data = response; 
                    })
                }
            }
            row.push(element);
        }
        return row;
    }
    const generateDetailFieldValue =(array) =>  {
        let result = {};
        for (let index = 0; index < array.length; index++) {
            const element = array[index];
            if(element.field) {
                let el;
                let parent_count = 1;

                for (let y = 0; y < element.form_detail_field_value.length; y++) {
                    const subElement = element.form_detail_field_value[y];
                    el = {
                        ['line_'+subElement.line_number+'#fieldid_'+subElement.fdf_id]: subElement.value 
                    }
                    parent_count++;
                    Object.assign(result, el)
                }
            }
        }
        return result;
    }
    const fixedHeaderFooterFieldsArray =(data) =>  {
        let header_footer_field = [];
        for (let index = 0; index < data.length; index++) {
            const element = data[index];
            let row;
            if(element.field) {
                row = {
                    id: element.id,
                    column: element.column,
                    display_name: element.field.display_name,
                    field_custom_name: element.field_custom_name,
                    field_id: element.field.id,
                    random_id: Math.floor(Math.random() * 100) + Math.floor(Math.random() * 100) + 1,
                    row: element.row,
                    source_type: element.field.source_type,
                    type: element.field.field_type.name,
                    usage: element.usage,
                    readonly: element.readonly == "1" ? true : false,
                    aggregate_field: false,
                    aggregate_function: null,
                    aggregate_column_field_id: null,
                    aggregate_column: null,
                    validation: element.validation,
                    hf_field_value_count: element.hf_field_value_count,
                    label_format: element.field.label_format,
                    label_value: element.field.label_value,
                }
            } else if(!element.field && element.aggregate_function) {
                row = {
                    id: element.id,
                    column: element.column,
                    display_name: "Aggregate Function",
                    field_custom_name: null,
                    field_id: null,
                    random_id: Math.floor(Math.random() * 100) + Math.floor(Math.random() * 100) + 1,
                    row: element.row,
                    source_type: "none",
                    type: "n/a",
                    usage: element.usage,
                    readonly: element.readonly == "1" ? true : false,
                    aggregate_field: element.aggregate_field == "1" ? true : false,
                    aggregate_function: element.aggregate_function,
                    aggregate_column_field_id: parseInt(element.aggregate_column_field_id),
                    aggregate_column: element.aggregate_column_field ? element.aggregate_column_field.parameter_name : null,
                    validation: null,
                    hf_field_value_count: element.hf_field_value_count,
                    label_format: null,
                    label_value: null,
                }
            } else {
                row = {
                    id: element.id,
                    column: element.column,
                    display_name:"Empty Block",
                    field_custom_name: null,
                    field_id: null,
                    random_id: Math.floor(Math.random() * 100) + Math.floor(Math.random() * 100) + 1,
                    row: element.row,
                    source_type: "none",
                    type: "n/a",
                    usage: element.usage,
                    validation: null,
                    readonly: false,
                    aggregate_field: element.aggregate_field == "1" ? true : false,
                    aggregate_column_field_id: null,
                    aggregate_column: null,
                    aggregate_function: null,
                    hf_field_value_count: 0,
                    label_format: null,
                    label_value: null,
                }
            }
            header_footer_field.push(row);
        }
        return header_footer_field;
    }
    const fixedDetailFieldsArray =(data) =>  {
        let detail_field = [];
        for (let index = 0; index < data.length; index++) {
            const element = data[index];
            let row;
            if(element.field) {
                row = {
                    id: element.id,
                    column: element.column,
                    display_name: element.field.display_name,
                    field_custom_name: element.field_custom_name,
                    parameter_name: element.field.parameter_name,
                    field_id: element.field.id,
                    source_type: element.field.source_type,
                    type: element.field.field_type.name,
                    validation: element.validation,
                    readonly: element.readonly == '1' ? true : false,
                    d_field_value_count: element.d_field_value_count,
                    label_format: element.field.label_format,
                    label_value: element.field.label_value,
                }
            } else {
                row = {
                    id: element.id,
                    column: element.column,
                    display_name:"Empty Block",
                    field_id: null,
                    field_custom_name: null,
                    source_type: "none",
                    type: "n/a",
                    validation: element.validation,
                    readonly: false,
                    d_field_value_count: element.d_field_value_count,
                    label_format: null,
                    label_value: null,
                }
            }
            detail_field.push(row);
        }
        return detail_field;
    }
    const filterObjectFromArray =(array, key, value) =>  {
        return array.filter(function (obj) {
            return obj[key] === value;
        });
    }
    const sortDetailFields =(detail_field) =>  {
        return detail_field.sort(function (x, y) {
            return x.column - y.column;
        });
    }
    const sortHeaderFooterFields =(header_footer_field) =>  {
        return header_footer_field.sort(function (x, y) {
            return parseInt(x.row) - parseInt(y.row) || parseInt(x.column) - parseInt(y.column);
        });
    }
    const generateDefaultValueToField = (fields) => {
        let defaultValue = {};
        for (let index = 0; index < fields.length; index++) { 
            const element = fields[index];
            if(element.field) {
                if(element.field.field_type.name == 'dropdown' 
                    && element.field.field_drop_down_values_data.length == 1 
                        && (element.validation && element.validation.split('|')[0] == 'required')) { // dropdown that has only one value
                    Object.assign(defaultValue, { [element.id] : element.field.field_drop_down_values_data[0].value})
                } else if((element.field.field_type.name == 'text' 
                    || element.field.field_type.name == 'number' 
                        || element.field.field_type.name == 'textarea'
                            || element.field.field_type.name == 'dropdown'
                                || element.field.field_type.name == 'input_search')
                                    && element.field.default_value 
                                        && (!element.form_header_footer_field_value
                                            || element.form_detail_field_value)) {
                    Object.assign(defaultValue, { [element.id] : element.field.default_value})
                } else if (element.field.field_type.name == 'date'
                                && element.field.default_value 
                                    && (!element.form_header_footer_field_value
                                        || element.form_detail_field_value)) {
                    if(element.field.default_value == 'current_date()') {
                        Object.assign(defaultValue, { [element.id] : new Date()})
                    } else {          
                        let date = new Date(element.field.default_value);
                        let now = new Date();
                        let currentTime = {
                            hours: now.getHours(),
                            minutes: now.getMinutes(),
                            seconds: now.getSeconds(),
                            milliseconds: now.getMilliseconds()
                        };
                        date.setHours(currentTime.hours);
                        date.setMinutes(currentTime.minutes);
                        date.setSeconds(currentTime.seconds);
                        date.setMilliseconds(currentTime.milliseconds);

                        Object.assign(defaultValue, { [element.id] : date })
                    }
                }
            }
        }
        return defaultValue;
    }
    const computeDefaultValue = (expression, args, parameters) => {
        if (parameters.length === 1) {
            return args[parameters[0]];
        }
        // Check if it's a mathematical equation
        if (/^[a-zA-Z0-9_+*\-/()\s]+$/.test(expression)) {

            const validDates = parameters.filter(param => args[param] && isValidDate(args[param]));
            if (validDates.length === 2) {

                const interval = getDateDifference(args[validDates[0]], args[validDates[1]]);
                const year = formatIntervalPart(interval.years, 'year', 'years');
                const month = formatIntervalPart(interval.months, 'month', 'months');
                const day = formatIntervalPart(interval.days, 'day', 'days');
                const difference = [year, month, day].filter(Boolean).join(", ");
                return difference || "0";
            } else {
                parameters.forEach(param => {
                  
                    if (args[param]) {
                        let argument = args[param];
                        if (isConvertibleToMonths(argument)) {
                            argument = convertToMonths(argument);
                        }
                        expression = expression.replace(param, argument);
                    }
                });
            }
            try {
                return eval(expression); // Evaluate the mathematical expression
            } catch (e) {
                return expression; // Return the original expression if evaluation fails
            }
        } else {
            return 'Invalid Expression';
        }
    }
    const isValidDate = (dateString, format = 'YYYY-MM-DD') => {
        // Match the format strictly
        const formatRegex = {
            'YYYY-MM-DD': /^\d{4}-\d{2}-\d{2}$/,
            'DD-MM-YYYY': /^\d{2}-\d{2}-\d{4}$/,
            'MM-DD-YYYY': /^\d{2}-\d{2}-\d{4}$/
        };

        // Check if the date matches the expected format\
        if (!formatRegex[format] || !formatRegex[format].test(dateString)) {
            return false;
        }

        // Parse the date and check if it's valid
        const dateParts = dateString.split('-');
        let year, month, day;

        switch (format) {
            case 'YYYY-MM-DD':
                [year, month, day] = dateParts.map(Number);
                break;
            case 'DD-MM-YYYY':
                [day, month, year] = dateParts.map(Number);
                break;
            case 'MM-DD-YYYY':
                [month, day, year] = dateParts.map(Number);
                break;
            default:
                return false;
        }

        // Check if the date is valid using the JavaScript Date object
        const date = new Date(year, month - 1, day);
        return (
            date.getFullYear() === year &&
            date.getMonth() === month - 1 &&
            date.getDate() === day
        );
    }
    const getDateDifference = (date1, date2) => {
        const d1 = new Date(date1);
        const d2 = new Date(date2);
        const diffTime = Math.abs(d2 - d1);
        const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
        return {
            years: Math.floor(diffDays / 365),
            months: Math.floor((diffDays % 365) / 30)
            // days: diffDays % 30
        };
    }
    const formatIntervalPart = (value, singular, plural) => {
        if (value === 1) {
            return `${value} ${singular}`;
        } else if (value > 1) {
            return `${value} ${plural}`;
        }
        return null;
    }
    const isConvertibleToMonths = (string) => {
        return /\d+\s*(year|month|day)/i.test(string);
    }
    const convertToMonths = (string) => {
        let totalMonths = 0;

        const yearMatch = string.match(/(\d+)\s*year/i);
        if (yearMatch) totalMonths += parseInt(yearMatch[1], 10) * 12;

        const monthMatch = string.match(/(\d+)\s*month/i);
        if (monthMatch) totalMonths += parseInt(monthMatch[1], 10);

        const dayMatch = string.match(/(\d+)\s*day/i);
        if (dayMatch) totalMonths += Math.round(parseInt(dayMatch[1], 10) / 30);

        return totalMonths;
    }
export default {
    generateHeaderFooterField,
    generateDropdownValueFromAPIField,
    generateHeaderFooterFieldValue,
    generateDetailField,
    generateDetailFieldValue,
    fixedHeaderFooterFieldsArray,
    fixedDetailFieldsArray,
    filterObjectFromArray,
    sortDetailFields,
    sortHeaderFooterFields,
    generateDefaultValueToField,
    computeDefaultValue
}