<template>
    <CCardBody >
        <span v-for="(usageElement, usageIndex) in headerFieldsComps" v-bind:key="usageIndex">
            <CRow v-for="(rowElement, rowElementIndex) in usageElement" v-bind:key="rowElementIndex">
                <template v-for="(singleElement, singleElementIndex) in rowElement" >
                    <CCol v-bind:key="singleElementIndex">
                        <template v-if="singleElement.type == 'label'">
                            <div :style="`white-space: pre-line; text-align:${singleElement.label_format}` ">{{singleElement.label_value}}</div>
                        </template>
                        <CInput 
                            v-if="singleElement.type == 'text' || singleElement.type == 'number' || singleElement.type == 'number_format' || singleElement.type == 'date'"
                            :label="singleElement.field_custom_name ? singleElement.field_custom_name : singleElement.display_name"
                            :readonly="singleElement.readonly"
                            :type="singleElement.type" 
                            :placeholder="'Enter '+singleElement.field_custom_name ? singleElement.field_custom_name : singleElement.display_name" 
                            autocomplete="off" 
                        />
                        <CTextarea
                            v-if="singleElement.type == 'textarea'"
                            :label="singleElement.field_custom_name ? singleElement.field_custom_name : singleElement.display_name"
                            :readonly="singleElement.readonly"
                            :placeholder="'Enter brief '+singleElement.field_custom_name ? singleElement.field_custom_name : singleElement.display_name" 
                            autocomplete="off"  
                        />
                        <template v-if="singleElement.type == 'dropdown' || singleElement.type == 'input_search'">
                            <v-select 
                                v-if="singleElement.type == 'dropdown'"
                                :readonly="singleElement.readonly"
                                :placeholder="'Please select '+singleElement.field_custom_name ? singleElement.field_custom_name : singleElement.display_name" 
                            >
                                <template #header>
                                    <div style="padding-bottom:9px;">{{singleElement.field_custom_name ? singleElement.field_custom_name : singleElement.display_name}}</div>
                                </template>
                            </v-select>
                            <CInput 
                                :label="singleElement.field_custom_name ? singleElement.field_custom_name : singleElement.display_name" 
                                v-if="singleElement.type == 'input_search'"
                                :placeholder="'Please select '+singleElement.field_custom_name ? singleElement.field_custom_name : singleElement.display_name" 
                                type="text" 
                                readonly 
                            >
                                <template #append>
                                    <CButton color="primary" 
                                    >
                                        <font-awesome-icon icon="search"/>
                                    </CButton>
                                </template>
                            </CInput>
                            <br>
                        </template>
                        <template v-if="singleElement.display_name == 'Aggregate Function'">
                            <CInput 
                                readonly
                                type="text" 
                                :placeholder="singleElement.function+ ' of '+ singleElement.aggregate_column" 
                                autocomplete="off" 
                            />
                        </template>
                    </CCol>
                </template>
            </CRow>
        </span>
        <hr v-if="detail_field && detail_field.length > 0">
        <CRow >
            <CCol v-for="(detailElement, detailIndex) in detail_field" v-bind:key="detailIndex">
                
                <template v-if="detailElement.type == 'label'">
                    <div :style="`white-space: pre-line; text-align:${detailElement.label_format}` ">{{detailElement.label_value}}</div>
                </template>
                <CInput 
                    v-if="detailElement.type == 'text' || detailElement.type == 'number' || detailElement.type == 'number_format' || detailElement.type == 'date'"
                    :label="detailElement.field_custom_name ? detailElement.field_custom_name : detailElement.display_name"
                    :type="detailElement.type" 
                    :readonly="detailElement.readonly"
                    :placeholder="'Enter '+detailElement.field_custom_name ? detailElement.field_custom_name : detailElement.display_name" 
                    autocomplete="off" 
                />
                <CTextarea
                    :label="detailElement.field_custom_name ? detailElement.field_custom_name : detailElement.display_name"
                    v-if="detailElement.type == 'textarea'"
                    :readonly="detailElement.readonly"
                    :placeholder="'Enter brief '+detailElement.field_custom_name ? detailElement.field_custom_name : detailElement.display_name" 
                    autocomplete="off"  
                />
                <template v-if="detailElement.type == 'dropdown' || detailElement.type == 'input_search'">
                    <v-select 
                        v-if="detailElement.type == 'dropdown'"
                        :readonly="detailElement.readonly"
                        :placeholder="'Please select '+detailElement.field_custom_name ? detailElement.field_custom_name : detailElement.display_name" 
                    >
                        <template #header>
                            <div style="padding-bottom:9px;">{{detailElement.field_custom_name ? detailElement.field_custom_name : detailElement.display_name}}</div>
                        </template>
                    </v-select>
                    <CInput 
                        :label="detailElement.field_custom_name ? detailElement.field_custom_name : detailElement.display_name" 
                        v-if="detailElement.type == 'input_search'"
                        :placeholder="'Please select '+detailElement.field_custom_name ? detailElement.field_custom_name : detailElement.display_name" 
                        type="text" 
                        readonly 
                    >
                        <template #append>
                            <CButton color="primary" 
                            >
                                <font-awesome-icon icon="search"/>
                            </CButton>
                        </template>
                    </CInput>
                    <br>
                </template>
            </CCol>
        </CRow>
        <hr>
        <span v-for="(usageElement, usageIndex) in footerFieldsComps" v-bind:key="usageIndex">
            <CRow v-for="(rowElement, rowElementIndex) in usageElement" v-bind:key="rowElementIndex">
                <template v-for="(singleElement, singleElementIndex) in rowElement" >
                    <CCol v-bind:key="singleElementIndex">
                        <template v-if="singleElement.type == 'label'">
                            <div :style="`white-space: pre-line; text-align:${singleElement.label_format}` ">{{singleElement.label_value}}</div>
                        </template>
                        <CInput 
                            v-if="singleElement.type == 'text' || singleElement.type == 'number' || singleElement.type == 'number_format' || singleElement.type == 'date'"
                            :label="singleElement.field_custom_name ? singleElement.field_custom_name : singleElement.display_name"
                            :readonly="singleElement.readonly"
                            :type="singleElement.type" 
                            :placeholder="'Enter '+singleElement.field_custom_name ? singleElement.field_custom_name : singleElement.display_name" 
                            autocomplete="off" 
                        />
                        <CTextarea
                            v-if="singleElement.type == 'textarea'"
                            :label="singleElement.field_custom_name ? singleElement.field_custom_name : singleElement.display_name"
                            :readonly="singleElement.readonly"
                            :placeholder="'Enter brief '+singleElement.field_custom_name ? singleElement.field_custom_name : singleElement.display_name" 
                            autocomplete="off"  
                        />
                        <template v-if="singleElement.type == 'dropdown' || singleElement.type == 'input_search'">
                            <v-select 
                                :readonly="singleElement.readonly"
                                v-if="singleElement.type == 'dropdown'"
                                :placeholder="'Please select '+singleElement.field_custom_name ? singleElement.field_custom_name : singleElement.display_name" 
                            >
                                <template #header>
                                    <div style="padding-bottom:9px;">{{singleElement.field_custom_name ? singleElement.field_custom_name : singleElement.display_name}}</div>
                                </template>
                            </v-select>
                            <CInput 
                                :label="singleElement.field_custom_name ? singleElement.field_custom_name : singleElement.display_name" 
                                v-if="singleElement.type == 'input_search'"
                                :placeholder="'Please select '+singleElement.field_custom_name ? singleElement.field_custom_name : singleElement.display_name" 
                                type="text" 
                                readonly 
                            >
                                <template #append>
                                    <CButton color="primary" 
                                    >
                                        <font-awesome-icon icon="search"/>
                                    </CButton>
                                </template>
                            </CInput>
                            <br>
                        </template>
                        <template v-if="singleElement.display_name == 'Aggregate Function'">
                            <CInput 
                                readonly
                                type="text" 
                                :placeholder="singleElement.aggregate_function+ ' of '+ singleElement.aggregate_column" 
                                autocomplete="off" 
                            />
                        </template>
                    </CCol>
                </template>
            </CRow>
        </span>
    </CCardBody>
</template>
<script>
export default {
    name: 'FormPreview',
    data () {
        return {
            form: {
                header_column:null,
                footer_column:null,
            },
            headerFieldsComps: null,
            detail_field: null,
            footerFieldsComps: null,
        }
    }
}
</script>